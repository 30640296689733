input,
button,
textarea {
	font-family: 'Poppins', sans-serif;
}

.image-gallery-thumbnails-container {
	display: flex;
}

.Toastify__toast-container {
	width: auto;
	min-width: 320px;
}

.Toastify__toast {
	padding: 8px 24px 8px 8px;
}
